import { navigate } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import React from 'react'

import useCmsFeatureToggle from '../hooks/useCmsFeatureToggle'
import { NpmTelesalesPage } from '../local_modules'
import { HAS_WINDOW } from '../local_modules/constants'
import { useSession } from '../local_modules/sdk/session'

import type { PageProps } from 'gatsby'

type Props = PageProps

const Page = ({ location: { search, href } }: Props) => {
  const { featureToggle } = useCmsFeatureToggle()

  const { locale } = useSession()
  const title = 'Vendas WhatsApp'
  const description = 'Vendas WhatsApp'

  const urlParams = new URLSearchParams(
    HAS_WINDOW ? search : 'terms=null&postalCode=null&isClub=false&userId=null'
  )

  const postalCode = urlParams.get('postalCode')
  const userId = urlParams.get('userId')
  const terms = urlParams.get('terms')
  const isClubParam = urlParams.get('isClub')
  const isClub = isClubParam === 'true'

  if (!postalCode || !terms || !featureToggle.hasTelesales) {
    if (!HAS_WINDOW) {
      return null
    }

    navigate('/404')

    return null
  }

  const termsArr = terms?.split(',')

  const telesalesData = {
    postalCode,
    userId,
    termsArr,
    isClub,
    href,
  }

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={title}
        description={description}
        language={locale}
        openGraph={{
          type: 'website',
          title,
          description,
        }}
      />
      {/*
        Sections: Components imported from '../components/sections' only.
        Do not import or render components from any other folder in here.
      */}
      <NpmTelesalesPage {...telesalesData} />
    </>
  )
}

export default Page
